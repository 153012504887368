/** @format */

import React from "react";
import { graphql } from "gatsby";

import RealizationCard from "../components/molecules/RealizationCard/RealizationCard";
import Container from "../components/atoms/Container/Container";
import Opinions from "../components/organisms/Opinions/Opinions";
import HelmetTemplate from "../components/molecules/HelmetTemplate/HelmetTemplate";
import Banner from "../components/molecules/Banner/Banner";

import {
  StyledContentRealizations,
  StyledRealizations,
} from "../components/atoms/Realizations/StyledContentRealizations";
import { StyledText } from "../components/atoms/StyledText/StyledText";

const Realizations = ({
  data: {
    allDatoCmsRealizacja: { nodes },
  },
}) => {
  return (
    <>
      <HelmetTemplate
        title="Realizacje"
        desc="Realizacje wykonane przez firmę Kon-stal"
      />
      <Container>
        <Banner isVideo disableBg hasHeight={false} />
        <StyledRealizations>
          <StyledText
            hasdeclaredfontsize="clamp(32px,2.292vw,44px)"
            hasdeclaredpadding="0 0 38px 0"
            hasdeclaredfontweight="700"
          >
            NASZE REALIZACJE:
          </StyledText>
          <StyledContentRealizations>
            {nodes.sort((a, b) => {
              const aDate = new Date(a.meta.publishedAt);
              const bDate = new Date(b.meta.publishedAt);
              
              return bDate - aDate;
            }).map((e) => (
              <RealizationCard
                image={e.miniaturka}
                local={e.lokalizacja}
                size={e.rozmiar}
                slug={e.slug}
                surface={e.powierzchnia}
                fullWidth
                key={e.slug}
              />
            ))}
          </StyledContentRealizations>
        </StyledRealizations>
        <Opinions />
      </Container>
    </>
  );
};

export default Realizations;

export const query = graphql`
  query allRealizations {
    allDatoCmsRealizacja {
      nodes {
        miniaturka {
          alt
          title
          gatsbyImageData
        }
        lokalizacja
        powierzchnia
        rozmiar
        slug
        meta {
          publishedAt
        }
      }
    }
  }
`;
