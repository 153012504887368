/** @format */

import styled from "styled-components";

export const StyledRealizations = styled.main`
  width: 100%;
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

export const StyledContentRealizations = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  align-items: center;
  justify-items: center;

  @media only screen and (max-width: 1208px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media only screen and (max-width: 357px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;
